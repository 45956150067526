<template>
  <el-container>
    <el-main>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>在线制版平台</el-breadcrumb-item>
        <el-breadcrumb-item>业务数据</el-breadcrumb-item>
        <el-breadcrumb-item>清流文件库</el-breadcrumb-item>
      </el-breadcrumb>
      <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
        <el-button v-if="editOptions.open && !selector && g_getUser() && g_getUser().isRoot" type="primary" @click="openHandle">添加数据</el-button>
      </SearchForm>
      <template v-if="tableOptions.open">
        <el-table :data="tableOptions.tableData" stripe border size="small"
                  header-cell-class-name="table_header"
                  cell-class-name="table_cell" style="width: 100%">
          <el-table-column type="index" label="序号" width="50"/>
          <template v-if="tableOptions.items">
            <template v-for="item in tableOptions.items">
              <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.props">
                    <template v-if="item.props[scope.row[item.name]]">
                      <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                        <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                          {{item.props[scope.row[item.name]]}}
                        </el-tag>
                      </template>
                      <template v-else>
                        {{item.props[scope.row[item.name]]}}
                      </template>
                    </template>
                    <template v-else-if="scope.row[item.name]">
                      <el-tag type="danger" disable-transitions>
                        {{scope.row[item.name]}}
                      </el-tag>
                    </template>
                    <template v-else>
                      {{scope.row[item.name]}}
                    </template>
                  </template>
                  <template v-else>
                    <el-tag type="warning" disable-transitions>
                      {{scope.row[item.name]}}
                    </el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                <template slot-scope="scope">
                  <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                          disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column v-else :prop="item.name" :label="item.label"/>
            </template>
          </template>
          <el-table-column min-width="200" label="操作">
            <template slot-scope="scope">
              <template v-if="!selector">
<!--                <el-button type="text" @click="openMenu(scope.row.id)">-->
<!--                  子菜单 <i class="el-icon-school"></i>-->
<!--                </el-button>-->
<!--                <el-divider direction="vertical"></el-divider>-->
                <template v-if="detailOptions.open">
                  <el-button type="text" @click="openDetail(scope.row)">
                    详细 <i class="el-icon-document"></i>
                  </el-button>
<!--                  <el-divider direction="vertical"></el-divider>-->
                </template>
<!--                <template v-if="scope.row.status === 0">-->
<!--                  <el-divider direction="vertical"></el-divider>-->
<!--                  <el-button type="text" @click="openImportQrCodeHandle(scope.row)">-->
<!--                    导入 <i class="el-icon-full-screen"></i>-->
<!--                  </el-button>-->
<!--                </template>-->
<!--                <template v-if="scope.row.status === 1">-->
<!--                  <el-divider direction="vertical"></el-divider>-->
<!--                  <el-button type="text" @click="openMakeDcpHandle(scope.row)">-->
<!--                    打包 <i class="el-icon-full-screen"></i>-->
<!--                  </el-button>-->
<!--                </template>-->
              </template>
              <template v-else>
                <el-button type="text" @click="selectHandle(scope.row)">
                  选择 <i class="el-icon-check"></i>
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="tableOptions.page"
            :page-size="tableOptions.limit"
            :total="tableOptions.total">
        </el-pagination>
      </template>
      <!--popup search-->
      <el-dialog title="添加数据" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
        <el-form :model="editForm" :rules="rules" ref="editForm">
          <el-descriptions class="margin-top"  :column="2"  border>
            <el-descriptions-item>
              <template slot="label">影片名称</template>
              <el-form-item prop="title">
                <el-input :disabled="true" style="width: 50%" v-model="editForm.title"></el-input>
                <el-button type="primary"  size="small" style="margin-left: 10px" @click="filmSelectorVisible = true">更换影片</el-button>
              </el-form-item>

            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">母版类型</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.type"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">区块链生成的ID</template>
              <el-form-item prop="cid">
                <el-input :disabled="true" v-model="editForm.cid"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">目录名</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.dir"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">编码标准</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.vcodec"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">声音制式</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.acodec"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">比特深度</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.bitdepth"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">比特率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.bitrate"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">声道</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.channels"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">色度</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.chroma"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">色域</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.colorspace"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">编码曲线</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.transferfunction"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">帧率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.framerate"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">高清</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.hdr"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">分辨率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.resolution"></el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <SingleFileLoader style="display: inline-block;margin:0 10px;" @done="loadFile">加载文件</SingleFileLoader>
        <el-button type="primary" @click="submitHandle('editForm')">保存</el-button>
        <el-button @click="closeEditForm">取消</el-button>
<!--        <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm" @save="saveFilmDcmp">-->
<!--          <template v-slot:header>-->
<!--            <el-descriptions-item>-->
<!--              <template slot="label">影片名称</template>-->
<!--              <el-form-item>-->
<!--                <el-input :value="title" disabled style="width: 50%"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-descriptions-item>-->
<!--          </template>-->
<!--        </EditForm>-->
      </el-dialog>
      <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
        <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
      </el-dialog>
      <el-dialog :title="qrCodeTitle" :visible.sync="qrCodeVisible" append-to-body destroy-on-close center :before-close="closeQrCode">
        <div style="margin:0 auto;display: flex;flex-direction: column;align-items: center;gap: 15px;">
          <BlobImageScrollable :url="url" :fileName="qrCodeImgName" :width="$store.getters.barcodeWidth" :height="$store.getters.barcodeHeight"></BlobImageScrollable>
        </div>
      </el-dialog>
      <el-dialog :title="makeDcpTitle" :visible.sync="makeDcpVisible" center width="70%" destroy-on-close append-to-body :before-close="closeMenu">
        <Menu2_MakeDcp :params="searchOptions.searchParams"></Menu2_MakeDcp>
      </el-dialog>
      <el-dialog title="选择影片" :visible.sync="filmSelectorVisible" center width="85%" append-to-body>
        <Menu2_Cpl :selector="true" @onselect="addFilmCallbackHandle"></Menu2_Cpl>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import SearchForm from "../common/OptionalSearchForm";
// import EditForm from "../common/EditForm";
import DetailPanel from "../common/DetailPanel";
import BlobImageScrollable from "../common/BlobImageScrollable";
import SingleFileLoader from "../common/SingleFileLoader";
import Menu2_Cpl from "./Menu2_Cpl";
import {Search_Type} from "../../constants";
const prefix = '/filmdcmp';
const getListUrl = `${prefix}/list`;
const getOneUrl = (id) => `${prefix}/one/${id}`
const getDetailUrl = (id) => `${prefix}/detail/${id}`
const postUrl = `${prefix}/save`;
const putUrl = (id) => `${prefix}/update/${id}`
const disableUrl = (id)=>`${prefix}/disable/${id}`
export default {
  name: 'Menu2_FilmDcmp',
  components: {SearchForm,DetailPanel,BlobImageScrollable,SingleFileLoader, Menu2_Cpl},
  data(){
    return{
      Search_Type,
      tableOptions:{
        tableData:[],
        page: 1,
        limit: 10,
        total: 0,
      },
      searchOptions:{
        searchForm: {},
        searchParams:{},
      },
      editOptions: {
        // postUrl: postUrl,
        getOneUrl: getOneUrl,
        putUrl: putUrl,
        addition: {},
      },
      detailOptions: {
        getDetailUrl : getDetailUrl,
        initData: null,
      },
      editFormVisible: false,
      makeDcpVisible: false,
      makeDcpTitle: '',
      detailPanelVisible: false,
      qrCodeVisible: false,
      qrCodeUrl: (id,width,height)=> `${this.g_getRequestBaseUrl()}${prefix}/barcode/import/${id}?width=${width}&height=${height}`,
      url: null,
      qrCodeTitle: '',
      qrCodeImgName: '',
      //新增
      editForm: {
        title: '',
        cid: '',
      },
      rules:{
        title: [
          { required: true, message: '影片名称为空', trigger: 'blur'},
        ],
        cid: [
          { required: true, message: '区块链生成的ID为空', trigger: 'blur'},
        ],
      },
      reloadTable: false,
      filmSelectorVisible: false
    }
  },
  props:['params','selector'],
  computed:{
    agreementId: {
      get(){
        if(this.params && this.params.length > 0){
          let result = this.params.filter(param=>param.name && param.name==='agreementId')
          if(result.length === 1) return result[0].value;
        }
        return '';
      }
    },
    title: {
      get(){
        if(this.params && this.params.length > 0){
          let result = this.params.filter(param=>param.name && param.name==='filmTitle')
          if(result.length === 1) return result[0].value;
        }
        return '';
      }
    }
  },
  created() {
    this.g_setupMetadataConfig(this, this.$options.name)
    if(!this.params){
      if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
        this.loadDynamicSelectData()
      }else{
        this.getTableData();
      }
    }
  },
  methods: {
    loadDynamicSelectData(){
      let needLoad = true;
      //加载动态键值对
      this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
        let dynamicUrl = item.dynamicUrl;
        if(!dynamicUrl || item.props) return;
        needLoad = false
        this.$axios.get(dynamicUrl).then(res=>{
          try {
            let params = item.dynamicParams.split(',')
            if(params.length !== 2) throw 'error parameter num'
            item.p1 = params[0]
            item.p2 = params[1]
            item.props = res.data
          }catch (e) {
            console.log(e)
          }
          this.getTableData()
        })
      })
      if(needLoad) this.getTableData()
    },
    getTableData(){
      this.$axios.get(getListUrl,{
        params: {
          ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
          ...this.searchOptions.searchForm
        }
      }).then(res=>{
        this.tableOptions.tableData = res.data.records;
        this.tableOptions.page = res.data.current;
        this.tableOptions.limit = res.data.size;
        this.tableOptions.total = res.data.total;
      })
    },
    handleSizeChange(limit){
      this.tableOptions.limit = limit;
      this.getTableData();
    },
    handleCurrentChange(page){
      this.tableOptions.page = page;
      this.getTableData();
    },
    handleSearch(){
      this.tableOptions.page = 1;
      this.getTableData();
    },
    disableHandle(id){
      this.$axios.put(disableUrl(id)).then(()=>{
        this.$message({
          showClose: true,
          message: `恭喜你，操作成功`,
          type: 'success',
          duration: 1500,
          onClose:() => {
            this.getTableData();
          }
        });
      })
    },
    closeMenu(done){
      this.searchOptions.searchParams = []
      done();
    },
    openMenu(id){
      this.menuVisible = true;
      this.searchOptions.searchParams = [{name:'id',value:id}];
    },
    openDetail(row){
      this.detailOptions.id = row.id;
      this.detailOptions.initData = row
      this.detailPanelVisible = true
    },
    editHandle(id){
      this.editOptions.id = id
      this.editFormVisible = true
    },
    closeEditForm(){
      // this.$refs['editForm'].resetFields();
      this.editFormVisible = false
      // this.editForm = {}
      if(this.reloadTable){
        this.reloadTable = false
        this.getTableData()
      }
    },
    closeDetailPanel(){
      this.detailOptions.id = null
      this.detailPanelVisible = false
    },
    selectHandle(row){
      this.$emit('onselect', row)
    },
    openHandle(){
      this.editForm = {title:this.title, cid:'',agreementId: this.agreementId};
      this.editFormVisible = true;
    },
    submitHandle(formName){
      // console.log(this.editForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(postUrl, {...this.editForm}).then(()=>{
            this.$message({
              showClose: true,
              message: `恭喜你，操作成功`,
              type: 'success',
              duration: 1500,
              onClose:() => {

              }
            });
            this.reloadTable = true;
            this.editForm = {title: this.editForm.title, cid: '',agreementId: this.editForm.agreementId};
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      // let form = {...editForm, agreementId: this.agreementId, title: this.title}
      // console.log(form)
    },
    loadFile(file){
      let reader = new FileReader();
      reader.onload = e => {
        try {
          const result = JSON.parse(e.target.result);
          const audio = result.minfo.audio
          if(!audio || !audio.ChannelLayout || audio.ChannelLayout.indexOf('Discrete-')>=0){
            this.$message.error('音频文件格式不满足要求');
          }else{
            this.editForm.jsonsrc = e.target.result
            this.editForm.type = result.minfo.type
            this.editForm.cid = result.minfo.uuid
            // this.editForm.dir = result.minfo
            this.editForm.vcodec = result.minfo.video.vCodec
            this.editForm.acodec = result.minfo.audio.aCodec
            this.editForm.bitdepth = result.minfo.video.bitDepth
            this.editForm.bitrate = parseInt(result.minfo.video.Bitrate)
            this.editForm.channels = result.minfo.video.aChannels
            // this.editForm.chroma = result.minfo.video.
            this.editForm.colorspace = result.minfo.video.ColorSpace
            // this.editForm.transferfunction = result.minfo
            this.editForm.framerate = result.minfo.video.FrameRate
            // this.editForm.hdr = result.minfo
            this.editForm.resolution = result.minfo.video.WidthPixels + '*' + result.minfo.video.HeightPixels
            this.editForm.contentid = result.minfo.uuid
            this.$message.success(`加载成功`)
          }
        }catch (e) {
          this.$message.error("文件解析失败")
        }
      }
      reader.readAsText(file.raw);
    },
    openImportQrCodeHandle(row){
      this.qrCodeVisible = true
      this.url = this.qrCodeUrl(row.id, this.$store.getters.barcodeWidth , this.$store.getters.barcodeHeight)
      this.qrCodeTitle = `[导入清流文件]${row.title}_${row.type}`
      this.qrCodeImgName = `[导入清流文件]${row.title}_${row.type}.png`
    },
    closeQrCode(){
      this.url = ''
      this.qrCodeVisible = false
    },
    openMakeDcpHandle(row){
      this.searchOptions.searchParams = [
        {name:'filmDcmpId', value: row.id},
        {name:'agreementId', value: this.agreementId},
        {name:'fileName', value: `${row.title}_${row.type}`}
      ]
      this.makeDcpTitle = `制作发行版(${row.title}_${row.type})`
      this.makeDcpVisible = true
    },
    addFilmCallbackHandle(data){
      this.editForm.title = data.filmTitle
      this.editForm.agreementId = data.id
      this.filmSelectorVisible = false
    },
  }
}
</script>

<style scoped>
  :deep(.el-form-item){
    margin-bottom: 0;
  }
</style>