<template>
  <el-container>
    <el-main>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>在线制版平台</el-breadcrumb-item>
        <el-breadcrumb-item>任务列表</el-breadcrumb-item>
        <el-breadcrumb-item>制作影片包</el-breadcrumb-item>
      </el-breadcrumb>
      <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
        <el-button v-if="editOptions.open && !selector && g_getUser() && g_getUser().isRoot" type="primary" @click="openHandle">新建任务</el-button>
      </SearchForm>
      <template v-if="tableOptions.open">
        <el-table :data="tableOptions.tableData" stripe border size="small"
                  header-cell-class-name="table_header"
                  cell-class-name="table_cell" style="width: 100%">
          <el-table-column type="index" label="序号" width="50"/>
          <template v-if="tableOptions.items">
            <template v-for="item in tableOptions.items">
              <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.props">
                    <template v-if="item.props[scope.row[item.name]]">
                      <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                        <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                          {{item.props[scope.row[item.name]]}}
                        </el-tag>
                      </template>
                      <template v-else>
                        {{item.props[scope.row[item.name]]}}
                      </template>
                    </template>
                    <template v-else-if="scope.row[item.name]">
                      <el-tag type="danger" disable-transitions>
                        {{scope.row[item.name]}}
                      </el-tag>
                    </template>
                    <template v-else>
                      {{scope.row[item.name]}}
                    </template>
                  </template>
                  <template v-else>
                    <el-tag type="warning" disable-transitions>
                      {{scope.row[item.name]}}
                    </el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                <template slot-scope="scope">
                  <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                          disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column v-else :prop="item.name" :label="item.label"/>
            </template>
          </template>
          <el-table-column min-width="100" label="操作">
            <template slot-scope="scope">
              <template v-if="!selector">
                <template v-if="detailOptions.open">
                  <el-button type="text" @click="openDetail(scope.row)">
                    详细 <i class="el-icon-document"></i>
                  </el-button>
                  <template v-if="scope.row.status === 1">
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click="openVersionDetail(scope.row)">
                      影片版本详细 <i class="el-icon-document"></i>
                    </el-button>
                    <template v-if="g_getUser() && g_getUser().isRoot">
                      <el-divider direction="vertical"></el-divider>
                      <el-button type="text" @click="openDkdmTask(scope.row)">
                        制作DKDM <i class="el-icon-reading"></i>
                      </el-button>
                    </template>
                  </template>
                </template>
                <template v-if="scope.row.status === 0">
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="openImportQrCodeHandle(scope.row)">
                    生成二维码 <i class="el-icon-full-screen"></i>
                  </el-button>
                </template>
              </template>
              <template v-else>
                <el-button type="text" @click="selectHandle(scope.row)">
                  选择 <i class="el-icon-check"></i>
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="tableOptions.page"
            :page-size="tableOptions.limit"
            :total="tableOptions.total">
        </el-pagination>
      </template>
      <!--popup search-->
      <el-dialog title="选择清流文件" :visible.sync="menuVisible" center width="70%" append-to-body :before-close="closeMenu">
        <Menu2_FilmDcmp :selector="true" :params="searchOptions.searchParams" @onselect="selectFilmDcmpCallbackHandle"></Menu2_FilmDcmp>
      </el-dialog>
      <el-dialog title="新建任务" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
        <el-form :model="editForm" :rules="rules" ref="editForm">
          <el-descriptions class="margin-top"  :column="2"  border>
            <el-descriptions-item>
              <template slot="label">区块链ID</template>
              <el-form-item prop="title">
                <el-input :disabled="true" style="width: 50%" v-model="editForm.title"></el-input>
                <el-button type="primary"  size="small" style="margin-left: 10px" @click="openMenu">更换清流文件</el-button>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">编码标准</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.vcodec"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">声音制式</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.acodec"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">比特深度</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.bitdepth"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">比特率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.bitrate"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">声道</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.channels"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">色度</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.chroma"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">色域</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.colorspace"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">编码曲线</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.transferfunction"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">帧率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.framerate"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">高清</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.hdr"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">分辨率</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.resolution"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">制版对象类型</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.targettype"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">转换函数</template>
              <el-form-item>
                <el-input :disabled="true" v-model="editForm.transferfunction"></el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <el-button type="primary" @click="loadDcpverHandle">选择制版格式</el-button>
        <el-button type="primary" @click="submitHandle('editForm')">保存</el-button>
        <el-button @click="closeEditForm">取消</el-button>
      </el-dialog>
      <el-dialog title="选择制版格式" :visible.sync="selectDcpverVisible" center width="85%" append-to-body>
        <Menu2_DcpVer :selector="true" @onselect="selectDcpverCallbackHandle"></Menu2_DcpVer>
      </el-dialog>
      <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
        <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="清流详细"></DetailPanel>
      </el-dialog>
      <el-dialog :visible.sync="versionDetailVisible" center width="70%" append-to-body :before-close="closeMenu">
        <Menu2_Version :params="searchOptions.searchParams"></Menu2_Version>
      </el-dialog>
      <el-dialog :title="qrCodeTitle" :visible.sync="qrCodeVisible" append-to-body destroy-on-close center :before-close="closeQrCode">
        <div style="margin:0 auto;display: flex;flex-direction: column;align-items: center;gap: 15px;">
          <BlobImageScrollable :url="url" :fileName="qrCodeImgName" :width="$store.getters.barcodeWidth" :height="$store.getters.barcodeHeight"></BlobImageScrollable>
        </div>
      </el-dialog>
      <el-dialog title="新建制作DKDM任务" :visible.sync="dkdmTaskVisible" width="85%" center append-to-body :before-close="closeEditForm">
        <el-form :model="editForm" :rules="dkdmRules" ref="dkdmEditForm">
          <el-descriptions class="margin-top"  :column="1"  border>
            <el-descriptions-item>
              <template slot="label">影片版本</template>
              <el-form-item prop="versionName">
                <el-input :disabled="true" style="width: 50%" v-model="editForm.versionName"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">运营平台</template>
              <el-form-item prop="opcode">
                <el-input :disabled="true" style="width: 50%" v-model="editForm.platformName"></el-input>
                <el-button type="primary"  size="small" style="margin-left: 10px" @click="openPlatformHandle">更换运营平台</el-button>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">开始日期</template>
              <el-form-item  prop="startdate">
                <el-date-picker
                    v-model="editForm.startdate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">结束日期</template>
              <el-form-item  prop="enddate">
                <el-date-picker
                    v-model="editForm.enddate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <el-button type="primary" @click="submitDkdmHandle('dkdmEditForm')">保存</el-button>
        <el-button @click="dkdmTaskVisible=false">取消</el-button>
      </el-dialog>
      <el-dialog title="选择运营平台" :visible.sync="selectPlatformVisible" center width="85%" append-to-body>
        <Menu3_Platform :selector="true" @onselect="selectPlatformCallbackHandle"></Menu3_Platform>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import SearchForm from "../common/OptionalSearchForm";
import Menu2_FilmDcmp from "./Menu2_FilmDcmp";
import DetailPanel from "../common/DetailPanel";
import BlobImageScrollable from "../common/BlobImageScrollable";
import {Search_Type} from "../../constants";
import Menu2_DcpVer from "./Menu2_DcpVer";
import Menu2_Version from "./Menu2_Version";
import Menu3_Platform from "../manager/Menu3_Platform";
const prefix = '/task/makedcp';
const getListUrl = `${prefix}/list`;
const getOneUrl = (id) => `${prefix}/one/${id}`
const getDetailUrl = (id) => `${prefix}/detail/${id}`
const postUrl = `${prefix}/save`;
const versionInfoUrl = (id)=>`/film/version/one/${id}`
const postDkdmTaskUrl = '/task/makedkdm/save'
export default {
  name: 'Menu1_MakeDcpTask',
  components: {SearchForm,Menu2_FilmDcmp,DetailPanel,BlobImageScrollable,Menu2_DcpVer,Menu2_Version,Menu3_Platform},
  data(){
    return{
      Search_Type,
      tableOptions:{
        tableData:[],
        page: 1,
        limit: 10,
        total: 0,
      },
      searchOptions:{
        searchForm: {},
        searchParams:{},
      },
      editOptions: {
        postUrl: postUrl,
        getOneUrl: getOneUrl,
      },
      detailOptions: {
        getDetailUrl : getDetailUrl,
      },
      editFormVisible: false,
      menuVisible: false,
      selectDcpverVisible: false,
      detailPanelVisible: false,
      dkdmTaskVisible: false,
      versionDetailVisible: false,
      selectPlatformVisible: false,
      editForm: {
        title: '',
      },
      rules:{
        title: [
          { required: true, message: '区块链ID为空', trigger: 'blur'},
        ],
      },
      dkdmRules:{
        versionName: [
          { required: true, message: '影片版本为空', trigger: 'blur'},
        ],
        opcode: [
          { required: true, message: '平台编号为空', trigger: 'blur'},
        ],
        startdate: [
          { required: true, message: '开始日期为空', trigger: 'blur'},
        ],
        enddate: [
          { required: true, message: '结束日期为空', trigger: 'blur'},
        ],
      },
      qrCodeVisible: false,
      qrCodeUrl: (id,width,height)=> `${this.g_getRequestBaseUrl()}${prefix}/createqrcode/${id}?width=${width}&height=${height}`,
      url: null,
      qrCodeTitle: '',
      qrCodeImgName: '',
    }
  },
  props:['params','selector'],
  created() {
    this.g_setupMetadataConfig(this, this.$options.name)
    if(!this.params){
      if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
        this.loadDynamicSelectData()
      }else{
        this.getTableData();
      }
    }
  },
  methods: {
    loadDynamicSelectData(){
      let needLoad = true;
      //加载动态键值对
      this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
        let dynamicUrl = item.dynamicUrl;
        if(!dynamicUrl || item.props) return;
        needLoad = false
        this.$axios.get(dynamicUrl).then(res=>{
          try {
            let params = item.dynamicParams.split(',')
            if(params.length !== 2) throw 'error parameter num'
            item.p1 = params[0]
            item.p2 = params[1]
            item.props = res.data
          }catch (e) {
            console.log(e)
          }
          this.getTableData()
        })
      })
      if(needLoad) this.getTableData()
    },
    getTableData(){
      this.$axios.get(getListUrl,{
        params: {
          ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
          ...this.searchOptions.searchForm
        }
      }).then(res=>{
        this.tableOptions.tableData = res.data.records;
        this.tableOptions.page = res.data.current;
        this.tableOptions.limit = res.data.size;
        this.tableOptions.total = res.data.total;
      })
    },
    handleSizeChange(limit){
      this.tableOptions.limit = limit;
      this.getTableData();
    },
    handleCurrentChange(page){
      this.tableOptions.page = page;
      this.getTableData();
    },
    handleSearch(){
      this.tableOptions.page = 1;
      this.getTableData();
    },
    closeMenu(done){
      this.searchOptions.searchParams = []
      done();
    },
    openMenu(){
      this.menuVisible = true;
      this.searchOptions.searchParams = [{name:'status',value:1}];
    },
    openDetail(row){
      this.detailOptions.id = row.id;
      //this.detailOptions.initData = row
      this.detailPanelVisible = true
    },
    openVersionDetail(row){
      this.versionDetailVisible = true;
      this.searchOptions.searchParams = [{name:'cpluuid',value:row.cpluuid}];
    },
    editHandle(id){
      this.editOptions.id = id
      this.editFormVisible = true
    },
    closeEditForm(){
      this.editFormVisible = false
      this.dkdmTaskVisible = false
      if(this.reloadTable){
        this.reloadTable = false
        this.getTableData()
      }
    },
    closeDetailPanel(){
      this.detailOptions.id = null
      this.detailPanelVisible = false
    },
    selectHandle(row){
      this.$emit('onselect', row)
    },
    openHandle(){
      this.editForm = {title:''};
      this.editFormVisible = true;
    },
    submitHandle(formName){
      // console.log(this.editForm)
      if(!this.editForm.dcpVerId){
        this.$message.error("版本格式未选择");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(postUrl, this.editForm).then(()=>{
            this.$message({
              showClose: true,
              message: `恭喜你，操作成功`,
              type: 'success',
              duration: 1500,
              onClose:() => {
                this.getTableData()
              }
            });
            this.editFormVisible = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      // let form = {...editForm, agreementId: this.agreementId, title: this.title}
      // console.log(form)
    },
    selectFilmDcmpCallbackHandle(data){
      this.editForm = {title: data.contentid, filmdcmpId: data.id}
      this.menuVisible = false
    },
    openImportQrCodeHandle(row){
      this.qrCodeVisible = true
      this.url = this.qrCodeUrl(row.id, this.$store.getters.barcodeWidth , this.$store.getters.barcodeHeight)
      this.qrCodeTitle = `[导入清流文件]${row.filmTitle}_${row.contentid}`
      this.qrCodeImgName = `[导入清流文件]${row.filmTitle}_${row.contentid}.png`
    },
    closeQrCode(){
      this.url = ''
      this.qrCodeVisible = false
    },
    loadDcpverHandle(){
      this.selectDcpverVisible = true
    },
    selectDcpverCallbackHandle(data){
      this.editForm = {...this.editForm, ...data,dcpVerId: data.id}
      this.selectDcpverVisible = false
    },
    openDkdmTask(row){
      this.$axios.get(versionInfoUrl(row.cpluuid)).then((res)=>{
        this.editForm = {versionName: res.data.versionName, cpluuid: res.data.cpluuid}
        this.dkdmTaskVisible = true
      })
    },
    openPlatformHandle(){
      this.selectPlatformVisible = true
    },
    selectPlatformCallbackHandle(data){
      this.editForm = {...this.editForm, platformName: `${data.name}(平台编号${data.platformCode})`, opcode: data.platformCode}
      // this.editForm.platformName = `${data.name}(平台编号${data.platformCode})`
      // this.editForm.opcode = data.platformCode
      this.selectPlatformVisible = false
    },
    submitDkdmHandle(formName){
      // console.log(this.editForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(postDkdmTaskUrl, this.editForm).then(()=>{
            this.$message({
              showClose: true,
              message: `恭喜你，操作成功`,
              type: 'success',
              duration: 1500,
              onClose:() => {
                this.getTableData()
              }
            });
            this.editFormVisible = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      // let form = {...editForm, agreementId: this.agreementId, title: this.title}
      // console.log(form)
    },
  }
}
</script>

<style scoped>
  :deep(.el-form-item){
    margin-bottom: 0;
  }
</style>